<template>
  <div class="full-width">
    <div
      v-click-out="() => (isOpen = false)"
      :class="['p-dropdown', `p-dropdown--${size}`]"
      :status
      :focused="isOpen"
      :error="errors.length > 0"
      @click="toggleMenu"
    >
      <div class="p-dropdown__cover">
        <div v-if="modelValue.icon" class="p-dropdown--animationSlideRight">
          <nuxt-img
            v-if="isIconCdn"
            :src="getAsset({ asset: modelValue.icon, type: 'bank' })"
            width="24"
            height="24"
          />
          <p-icon
            v-else
            class="p-dropdown__icon"
            :size="props.size === 'small' ? 'xmd' : 'md'"
            :icon="modelValue.icon"
          />
        </div>
        <div class="p-dropdown__inner">
          <input
            :id="id"
            v-model="modelValue.title"
            :data-test="testAttribute"
            class="p-dropdown__field"
            :name="id"
            readonly
          />
          <label
            v-if="label"
            :for="id"
            class="p-dropdown__label text-nowrap"
            :valid="modelValue.value ? true : false"
            v-text="label"
          />
        </div>
      </div>
      <p-menu
        v-if="isOpen"
        v-model="modelValue"
        :items="options"
        :direction="menuDirection"
        :width="menuWidth"
        :is-icon-cdn="isIconCdn"
        @on-change="handleMenuChange"
      />
      <p-icon
        icon="caret-down"
        size="md"
        color="fg-tertiary"
        class="p-dropdown__icon p-dropdown__icon--rotate"
      />
    </div>
    <p-error-message v-if="errorState" :name="name" class="p-text-input--error" />
  </div>
</template>

<script setup lang="ts">
import { useField, ErrorMessage as PErrorMessage } from 'vee-validate'

const isOpen = ref<boolean>(false)

interface ModelValue {
  value: string | number
  desc?: string | number
  title?: string | number
  icon?: string
}

const props = defineProps({
  id: {
    type: String,
    default: 'Provide an ID',
  },
  name: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'large',
    validator: (val: string) => ['small', 'medium', 'large'].includes(val),
  },
  label: {
    type: String,
    default: '',
  },
  options: {
    type: Array as () => ModelValue[],
    required: true,
  },
  status: {
    type: String,
    default: 'selectable',
    validator: (val: string) => ['disabled', 'readonly', 'selectable'].includes(val),
  },
  menuDirection: {
    type: String,
    default: 'bottom-right',
    validator: (val: string) =>
      ['top-left', 'top-right', 'bottom-left', 'bottom-right'].includes(val),
  },
  menuWidth: {
    type: String,
    default: '100%',
  },
  isIconCdn: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['on-change'])

const handleMenuChange = (item: any) => {
  isOpen.value = false
  setValue(item)
  emit('on-change', item)
}

const testAttribute = dataTest(props.id)

const modelValue = defineModel<ModelValue>('modelValue', {
  default: { icon: undefined, value: undefined, desc: undefined, title: undefined },
})

const { meta, errors, setValue } = useField(props.name, undefined, {
  initialValue: modelValue.value,
})

const errorState = computed<boolean>(() => {
  return !meta.valid && meta.validated
})

const toggleMenu = () => {
  props.status === 'selectable' && (isOpen.value = !isOpen.value)
}
</script>
